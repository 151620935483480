<template>
  <v-footer
    color="secondary"
    padless
  >
    <v-row
      class="py-0 my-0"
      justify="center"
      align="center"
      color="secondary"
      no-gutters
    >
      <v-col
        cols="12"
        xl="6"
        lg="9"
        md="9"
      >
        <v-row
          justify="center"
          color="secondary"
          no-gutters
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="6"
          >
            <v-img
              :class="`${$vuetify.breakpoint.smAndDown ? 'center-img' : ''}`"
              :src="require('@/assets/logo-footer.png')"
              max-width="190"
              max-height="190"
              width="100%"
              contain
            >
            </v-img>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            xl="6"
            class="text-center text-md-right text-lg-right text-xl-right"
            align-self="center"
          >
            <a
              v-for="social of socialMedia"
              :key="social.text"
              style="text-decoration: none;"
              :href="social.url"
              target="_blank"
            >
              <v-btn
                style="color:white;margin:10px 5px"
                icon
              >
                <v-icon size="24px">
                  {{ social.icon }}
                </v-icon>
              </v-btn>
            </a>
          </v-col>

          <v-col
            cols="12"
            class="text-center"
          >
            <v-btn
              v-for="link in links"
              :key="link.text"
              class="my-2"
              color="white"
              text
              :to="{name: link.to}"
            >
              {{ link.text }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      socialMedia: [
        {
          text: 'Facebook',
          icon: 'mdi-facebook',
          url: 'https://www.facebook.com/ConstrufacilMx'
        },
        {
          text: 'Instagram',
          icon: 'mdi-instagram',
          url: 'https://www.instagram.com/ConstrufacilMX/'
        },
        {
          text: 'Youtube',
          icon: 'mdi-youtube',
          url: 'https://www.youtube.com/channel/UC8pTQcWjEfVR8U6iDqqbK6g'
        },
      ],
      links: [
        {
          text: 'Aviso de privacidad',
          to: 'aviso-de-privacidad'
        },
        {
          text: 'Mi cuenta',
          to: ''
        },
        {
          text: 'Únete a la red Construfácil',
          to: ''
        },
        {
          text: 'Directorio de Tiendas',
          to: 'Directorio de tiendas'
        },
        {
          text: 'Contacto',
          to: ''
        },
      ]
    };
  }
};
</script>
<style scoped>
.theme--light.v-btn--active:before {
  opacity: 0 !important;
}
.center-img {
  /*left: 9.5rem !important;*/
  margin-right: auto !important;
  margin-left: auto !important;
  margin-top: auto !important;
}
.right-img {
  margin-right: auto !important;
}
.heigth{
  height: 10.625rem;
  margin: 0;
  padding: 0;
}
.centerRedesSociales{
  margin-right: auto !important;
  margin-left: auto !important;
}
.leftRedesSociales{
  left: 20.125rem;
}
</style>
